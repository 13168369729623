<template>
	<div>
		<div class="wrapper">
			<v-container
				class="pa-0"
				fluid
			>
				<!-- 타이틀 -->
				<v-col
					align="center"
					class="pa-0"
				>
					<v-row class="ma-0 py-5 justify-center main_tit">
						<v-col
							class="pa-0"
							cols="auto"
							align-self="center"
						>
							<v-img
								width="24"
								eager
								:src="require('@/assets/img/careflo/icon_care_flo.svg')"
							/>
						</v-col>
						<v-col
							class="pa-0 ml-1"
							align-self="center"
							cols="auto"
						>
							케어플로 서비스
						</v-col>
					</v-row>
				</v-col>

				<!-- 고객정보 -->
				<v-col
					cols="12"
					class="pa-4 mb-2 section"
				>
					<v-row class="ma-0 mb-5">
						<v-col
							class="pa-0"
							cols="6"
							align-self="center"
						>
							<h3>고객정보</h3>
						</v-col>
						<v-col
							class="pa-0"
							cols="6"
							align="end"
							align-self="center"
						>
							<v-btn
								text
								class="btn_change"
								@click="confirmCustom = true"
								>회원 정보 수정</v-btn
							>
						</v-col>
					</v-row>
					<div class="info_table">
						<v-row class="ma-0 mb-2">
							<v-col
								class="pa-0 th"
								lg="1"
								md="2"
								cols="3"
							>
								이름
							</v-col>
							<v-col
								class="pa-0 pl-3 pl-sm-0 td"
								cols="9"
							>
								{{ customerInfo.cust_name }}
							</v-col>
						</v-row>
						<v-row class="ma-0 mb-2">
							<v-col
								class="pa-0 th"
								lg="1"
								md="2"
								cols="3"
							>
								휴대폰번호
							</v-col>
							<v-col
								class="pa-0 pl-3 pl-sm-0 td"
								cols="9"
							>
								{{ customerInfo.cust_hand }}
							</v-col>
						</v-row>
						<v-row class="ma-0 mb-2">
							<v-col
								class="pa-0 th"
								lg="1"
								md="2"
								cols="3"
							>
								시공주소
							</v-col>
							<v-col
								class="pa-0 pl-3 pl-sm-0 td"
								cols="9"
							>
								{{ customerInfo.address }}
							</v-col>
						</v-row>
					</div>
				</v-col>

				<!-- 신청방법 -->
				<v-col
					cols="12"
					class="pa-4 mb-2 section"
				>
					<h3 class="mb-4">신청 방법</h3>
					<v-row class="ma-0 register_step">
						<v-col
							class="pa-0 item"
							cols="4"
							align="center"
						>
							<v-img
								class="mb-2"
								width="46"
								eager
								:src="require('@/assets/img/careflo/icon_resister1.svg')"
							/>
							<p class="step">신청</p>
							<p class="desc">유선/온라인</p>
							<v-img
								class="icon_arrow"
								width="10"
								eager
								:src="require('@/assets/img/careflo/icon_arrow.svg')"
							/>
						</v-col>
						<v-col
							class="pa-0 item"
							cols="4"
							align="center"
						>
							<v-img
								class="mb-2"
								width="46"
								eager
								:src="require('@/assets/img/careflo/icon_resister2.svg')"
							/>
							<p class="step">일정조율</p>
							<p class="desc">A/S기사 연락</p>
							<v-img
								class="icon_arrow"
								width="10"
								eager
								:src="require('@/assets/img/careflo/icon_arrow.svg')"
							/>
						</v-col>
						<v-col
							class="pa-0 item"
							cols="4"
							align="center"
						>
							<v-img
								class="mb-2"
								width="46"
								eager
								:src="require('@/assets/img/careflo/icon_resister3.svg')"
							/>
							<p class="step">A/S기사</p>
							<p class="desc">방문</p>
						</v-col>
					</v-row>
				</v-col>

				<!-- 신청내역 -->
				<v-col
					cols="12"
					class="pa-4 mb-5 section"
				>
					<h3 class="mb-5">A/S 신청내역</h3>
					<!-- 신청내역이 없을때 -->
					<v-col
						v-if="allAsList.length === 0"
						class="pa-0 py-6 no_list"
						align="center"
					>
						A/S 신청내역이 없습니다. <br />
						제품에 문제가 있을 시 <strong @click="handlerDialogAsButtonClick">A/S 신청하기</strong>를 <br />
						진행해주세요!
					</v-col>

					<!-- 신청내역이 있을때 -->
					<div
						v-else
						class="as_list"
					>
						<v-col
							v-for="(item, i) in displayedAsList"
							:key="i"
							class="pa-0 pt-4 pb-2 list_item"
							@click="handlerDialogAsListButtonClick(i)"
						>
							<v-row class="ma-0 mb-4">
								<!-- <v-col
									cols="2"
									sm="auto"
									class="pa-0"
									align-self="start"
								>
									<span class="flag">{{ item.as_gubun }}</span>
								</v-col> -->
								<v-col
									cols="12"
									class="pa-0 tit"
									align-self="start"
								>
									{{ item.title }}
								</v-col>
							</v-row>
							<v-row class="ma-0">
								<v-col class="pa-0 date">{{ item.receipt_date }}</v-col>
								<v-col
									align="end"
									class="pa-0 state ing"
									:class="
										item.progress_state_name === '접수중'
											? 'ing'
											: item.progress_state_name === '처리완료'
											? 'clear'
											: 'unable'
									"
								>
									{{ item.progress_state_name }}
								</v-col>
							</v-row>
						</v-col>

						<v-row
							v-if="allAsList.length > 5"
							class="ma-0 mt-4 justify-center"
						>
							<v-col
								class="pa-0"
								cols="4"
							>
								<CommonButtonsButton01
									:name="showMoreText"
									class="btn_more"
									color="f8f8f8"
									@click="showMore"
								/>
							</v-col>
						</v-row>
					</div>
				</v-col>

				<!-- 고객센터 정보 -->
				<v-col class="pa-0 px-4 mb-15">
					<div class="center_info">
						<v-row class="ma-0 justify-center">
							<v-col
								class="pa-0"
								cols="auto"
								align="end"
							>
								<div class="mr-1 txt_1">고객센터</div>
							</v-col>
							<v-col
								class="pa-0"
								cols="auto"
							>
								<div class="ml-2">
									<v-col class="pa-0 txt_2">1899-2837</v-col>
									<v-col class="pa-0 txt_3">평일 9:00 ~ 17:00(공휴일 외)</v-col>
								</div>
							</v-col>
						</v-row>
					</div>
				</v-col>
			</v-container>
		</div>
		<CommonButtonsButton01
			name="AS 신청하기"
			class="btn_register"
			color="#00592D"
			height="42"
			@click="handlerDialogAsButtonClick"
		/>

		<!-- AS신청하기 팝업 -->
		<CommonDialogDialogDynamicBasic
			v-if="dialogAs"
			:dialog="dialogAs"
			:title="type === 'create' ? 'AS신청하기' : 'A/S 신청 내역 수정'"
			:item="asItem"
			:type="type"
			:components-path="'home/careflo/register'"
			:width="800"
			@close="closeDialog"
		/>

		<!-- AS 내역보기 -->
		<CommonDialogDialogDynamicBasic
			v-if="dialogAsList"
			:dialog="dialogAsList"
			title="A/S 신청내역"
			:item="asItem"
			:components-path="'home/careflo/list'"
			:width="800"
			@modify="modify"
			@close="closeDialog"
		/>

		<CommonConfirm
			:dialog="confirmCustom"
			mobile
			:title="``"
			:text="`회원 정보를 수정하시겠습니까?<br>회원 정보를 초기화 후 재등록합니다.<br>(시공 일시, A/S 신청 여부 등의 이력은 초기화 되지 않습니다.)`"
			@close="confirmCustom = false"
			@submit="reset"
		/>
		<!-- AS신청하기 완료팝업 -->
		<!-- <CommonDialogDialogDynamicBasic
			v-if="dialogAs"
			:dialog="dialogAs"
			title="AS신청하기"
			:components-path="'home/careflo/clear'"
			:width="800"
			@close="dialogAs = false"
		/> -->
	</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import model from '@/mixins/model'
export default {
	name: 'CareFloCustomerAsInfo',
	mixins: [model],
	data: () => ({
		customerInfo: {},
		// as list
		allAsList: [],
		displayedAsList: [],
		batchSize: 5,
		currentIndex: 0,

		type: 'create',
		asItem: {},
		dialogAs: false,
		dialogAsList: false,
		confirmCustom: false,
	}),
	computed: {
		...mapGetters(['CAREFLO_GET_AUTH_USER']),
		showMoreText() {
			const totalCount = this.allAsList.length
			const displayedCount = this.currentIndex + this.batchSize - 5
			return `더보기 ${displayedCount > totalCount ? totalCount : displayedCount}/${totalCount}`
		},
	},
	watch: {},
	created() {
		this.getList()
	},
	mounted() {},
	methods: {
		...mapActions(['CAREFLO_ACT_CAREFLO_NFC_CODE', 'ADMIN_ACT_USER_CARE_FLO']),
		async getList() {
			const params = {
				nfc_code: this.CAREFLO_GET_AUTH_USER.nfc_code,
				cust_hand: this.CAREFLO_GET_AUTH_USER.hand,
				cust_name: this.CAREFLO_GET_AUTH_USER.name,
			}
			const items = { method: 'detail', params: params }
			await this.CAREFLO_ACT_CAREFLO_NFC_CODE(items).then(res => {
				console.log('CAREFLO_ACT_CAREFLO_NFC_CODE', res)
				this.customerInfo = res
				this.allAsList = res.careflo_aslist
				this.showMore()
			})
		},
		async reset() {
			const items = { method: 'deleteCustomerNfc', params: { nfc_code: this.CAREFLO_GET_AUTH_USER.nfc_code } }
			await this.ADMIN_ACT_USER_CARE_FLO(items).then(res => {
				console.log('ADMIN_ACT_USER_CARE_FLO', res)
				if (res) this.$router.push(`/careflo/${this.CAREFLO_GET_AUTH_USER.nfc_code}`)
			})
		},
		modify(item) {
			this.asItem = item
			this.type = 'modify'
			this.dialogAs = true
		},
		showMore() {
			const endIndex = Math.min(this.currentIndex + this.batchSize, this.allAsList?.length)
			this.displayedAsList = this.allAsList.slice(0, endIndex)
			this.currentIndex = endIndex
		},
		handlerDialogAsListButtonClick(i) {
			this.asItem = this.allAsList[i]
			this.dialogAsList = true
		},
		handlerDialogAsButtonClick() {
			this.asItem = {}
			this.type = 'create'
			this.dialogAs = true
		},
		closeDialog(mode) {
			if (mode === 'create') this.currentIndex = 0
			this.getList()
			this.dialogAs = false
			this.dialogAsList = false
		},
	},
}
</script>

<style scoped lang="scss">
// 두줄이상 (...)처리
@mixin ellipsis_2 {
	display: -webkit-box !important;
	word-wrap: break-word !important;
	-webkit-line-clamp: 2 !important;
	-webkit-box-orient: vertical !important;
	overflow: hidden !important;
	text-overflow: ellipsis !important;
}
.main_tit {
	font-size: $font_xl;
	font-weight: $fw_bold;
	line-height: 1;
	color: $color_font;
}
.sub_tit {
	font-size: $font_normal;
	color: $color_gray_7;
}

.section {
	background-color: $color_white;
	h3 {
		color: $color_font;
		font-size: $font_lg;
		font-weight: $fw_bold;
	}
	.desc {
		font-size: $font_normal;
		color: $color_gray_5;
	}
	.item {
		font-size: $font_normal;
		color: $color_gray_8;
	}
}

// 회원정보 수정
.v-btn.btn_change {
	border: 1px solid $color_gray_2;
	background-color: $color_white;
	border-radius: 99px;
	:deep(.v-btn__content) {
		font-size: $font_normal;
		color: $color_gray_8 !important;
	}
}

// 회원정보 테이블
.info_table {
	font-size: $font_normal;
	.th {
		color: $color_gray_6;
	}
	.td {
		color: $color_font;
		word-break: keep-all;
	}
}

// as 신청방법
.register_step {
	.item {
		position: relative;
		font-size: $font_sm;
		.step {
			font-weight: $fw_bold;
			color: $color_font;
		}
		.desc {
			color: $color_font;
			font-size: $font_sm;
		}
		.icon_arrow {
			position: absolute;
			top: 50%;
			right: -7px;
			transform: translate(0, -50%);
		}
	}
}

// as내역이 없을때
.no_list {
	background-color: $color_gray_1;
	border-radius: 8px;
	font-size: $font_normal;
	color: $color_gray_6;
	strong {
		color: #00ad50;
		border-bottom: 1px solid #00ad50;
		cursor: pointer;
	}
}

// as내역이 있을때
.as_list {
	.list_item {
		cursor: pointer;
		border-bottom: 1px solid $color_gray_2;
		.flag {
			padding: 2px 8px;
			font-size: $font_sm;
			border: 1px solid $color_gray_2;
			border-radius: 99px;
		}
		.tit {
			@include ellipsis_2;
			font-size: $font_normal;
			line-height: 1.3;
			word-break: keep-all;
		}
		.date {
			font-size: $font_sm;
			color: $color_gray_5;
		}
		.state {
			font-size: $font_sm;
			&.ing {
				color: $color_gray_8;
			}
			&.clear {
				color: #00ce7d;
			}
			&.unable {
				color: #fb4f4f;
			}
		}
	}
	// 더보기버튼
	.btn_more {
		border-radius: 99px;
		:deep(.v-btn__content) {
			font-size: $font_normal !important;
			span {
				color: $color_gray_6 !important;
			}
		}
	}
}

// 고객센터정보
.center_info {
	.txt_1 {
		font-size: $font_normal;
		color: $color_gray_6;
	}
	.txt_2 {
		font-size: $font_lg;
		font-weight: $fw_bold;
		color: $color_gray_8;
	}
	.txt_3 {
		font-size: $font_normal;
		color: $color_gray_8;
	}
}

.btn_register {
	position: fixed;
	bottom: 0;
	border-radius: 0;
	z-index: 1;
	:deep(.v-btn__content) {
		font-size: $font_lg;
		font-weight: $fw_bold;
	}
}
</style>
